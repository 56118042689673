import { NavCategorySub } from '@/types';
import React from 'react';
import ExternalLink from './ExternalLink';
import { isPageLink, isExternalLink } from './Navigation';
import PageLink from './PageLink';

function CategorySub(props: NavCategorySub) {
  const { value } = props;

  return (
    <>
      <div className='nav__list--category'>{value.title}</div>
      <ul>
        {value?.sub_nav?.map(item => {
          if (isPageLink(item)) {
            return (
              <li className='nav__list-item' key={item.id}>
                <PageLink {...item} />
              </li>
            );
          }
          if (isExternalLink(item)) {
            return (
              <li className='nav__list-item' key={item.id}>
                <ExternalLink {...item} />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </>
  );
}

export default CategorySub;
