import { WagtailImage } from '@/types';
import { addBaseUrl, removeBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';
import IconBaeder from '@images/Icon_Baeder.svg';
import IconSchiffahrt from '@images/Icon_Schifffahrt.svg';
import IconEvents from '@images/events-black.svg';
import Image from 'next/image';
import Link from 'next/link';

type SocialMediaData = {
  image: WagtailImage | null;
  link: string;
  title: string;
  id: number;
  svg: string | null;
  ariatext: string;
};

type FooterMenuData = {
  id: number;
  title: string;
  link_intern_full_url: string | null;
  link_extern: string | null;
};

type FooterData = {
  id: number;
  text_left: string | null;
  text_right: string | null;
  social_media: SocialMediaData[];
  footer_menu: FooterMenuData[];
};

type Props = {
  data: FooterData | null;
};

function wrapSpanElement(text: string) {
  const new_text = text.replace(/(<br\/.*?>(.*?)<a)/gm, (a, b, c) =>
    c ? `<br/> <span>${c}</span> <a` : '<br/> <a',
  );
  return new_text;
}

function Footer(props: Props) {
  const { data } = props;

  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__text'>
          <div className='footer__text--grid'>
            {data?.text_left ? (
              <div className='flow'>
                <RawHtml html={wrapSpanElement(data.text_left)} />

                <div className='icons'>
                  <p>
                    <strong>Unsere Freizeitangebote</strong>
                  </p>
                  <div className='wrapper'>
                    <a href='https://schwalbe.stadtwerke-witten.de/'>
                      <IconSchiffahrt />
                      <div>MS Schwalbe</div>
                    </a>
                    <a href='https://baeder.stadtwerke-witten.de/'>
                      <IconBaeder />
                      <div>Schwimmbäder</div>
                    </a>

                    <Link href='/events' className='eventlink'>
                      <IconEvents />
                      <div>Events</div>
                    </Link>
                  </div>
                  <div className='placeholder' />
                </div>
              </div>
            ) : null}
            {data?.text_right ? (
              <div className='right'>
                <RawHtml html={wrapSpanElement(data.text_right)} />
              </div>
            ) : null}
          </div>
        </div>

        {data?.social_media?.length ? (
          <div className='footer__social'>
            {data?.social_media.map(item => (
              <Link
                key={item.id}
                href={item.link}
                passHref
                className='footer__socialitem'
                aria-label={item.ariatext}
                target='_blank'
                rel='noreferrer'
              >
                {item.image ? (
                  <Image
                    src={addBaseUrl(item.image.meta.download_url)}
                    alt={item.image.title}
                    className='socialicon'
                    width={40}
                    height={40}
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                ) : null}
                {item.svg ? <RawHtml className='socialicon' html={item.svg} /> : null}
              </Link>
            ))}
          </div>
        ) : null}
        <div className='footer__menu'>
          {data?.footer_menu.length ? (
            <ul className='footer__menu-list'>
              {data.footer_menu.map(entry => (
                <li key={entry.id} className='footer__menu-list-item'>
                  {entry.link_intern_full_url ? (
                    <Link href={removeBaseUrl(entry.link_intern_full_url)} passHref>
                      {entry.title}
                    </Link>
                  ) : null}
                  {entry.link_extern && !entry.link_intern_full_url ? (
                    <Link
                      href={entry.link_extern}
                      target='_blank'
                      rel='noopener noreffer'
                      className='footer__menu-external-link'
                      passHref
                    >
                      {entry.title}
                    </Link>
                  ) : null}
                </li>
              ))}
            </ul>
          ) : null}
          <p className='footer__copy'>© {date} Stadtwerke Witten</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
