import { NavPage } from '@/types';
import { removeBaseUrl, addBaseUrl } from '@utils/url';
import Image from 'next/image';

import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

type Props = { isCategoryTop?: boolean } & NavPage;

function PageLink(props: Props) {
  const { value, isCategoryTop } = props;
  const router = useRouter();

  return (
    <Link
      className={clsx('nav__link', {
        active: router.asPath === `/${value.page.full_url}`,
        'nav__link--category-top button button--light': isCategoryTop,
      })}
      href={removeBaseUrl(value.page.full_url)}
      target={value.open_in_new_tab ? '_blank' : undefined}
    >
      {value.icon ? (
        <Image
          src={addBaseUrl(value.icon.file.src)}
          alt={value.icon.file.alt}
          className=''
          width={50}
          height={50}
          style={{
            objectFit: 'contain',
          }}
        />
      ) : null}
      {value.icon_svg ? (
        <Image src={addBaseUrl(value.icon_svg.file)} width={50} height={50} alt='' />
      ) : null}
      {value.override_title ? value.override_title : value.page.title}
    </Link>
  );
}

export default PageLink;
