import React, { useState } from 'react';
import Link from 'next/link';
import Logo from '@images/logo.svg';
import Navigation from '@components/Navigation';
import type { CustomNavitem } from '@/types';
import { WagtailImage } from '@/types';
import Image from 'next/image';
import { addBaseUrl } from '@/utils/url';
import RawHtml from '@components/RawHtml';
import ContactNav from '@components/ContactNav';

type HeaderProps = {
  svg: string;
  ariatext: string;
  image: WagtailImage | null;
  warning: string;
};

type Props = {
  data: CustomNavitem[];
  header?: HeaderProps | null;
};

function Header(props: Props) {
  const { data, header } = props;

  const [navOpen, setNavOpen] = useState('closed');

  return (
    <>
      <header className='header'>
        <Link href='/' passHref className='header__link' aria-label='Link auf die Startseite'>
          {header?.image ? (
            <Image
              src={addBaseUrl(header.image.meta.download_url)}
              alt={header.image.title}
              className='socialicon'
              width={40}
              height={40}
              style={{
                objectFit: 'contain',
              }}
            />
          ) : null}
          {header?.svg ? <RawHtml className='socialicon' html={header.svg} /> : null}
          {!header?.svg && !header?.image ? <Logo className='header__logo' /> : null}
        </Link>
        <div className='navwrapper'>
          <ContactNav navOpen={navOpen} setNavOpen={setNavOpen} />
          <Navigation data={data} navOpen={navOpen} setNavOpen={setNavOpen} />
        </div>
      </header>
      {header?.warning ? <RawHtml className='headerwarning' html={header.warning} /> : ''}
    </>
  );
}

export default Header;
