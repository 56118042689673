import { NavCategoryTop } from '@/types';
import React from 'react';
import Image from 'next/image';
import { addBaseUrl } from '@utils/url';
import CategorySub from './CategorySub';
import ExternalLink from './ExternalLink';
import { isExternalLink, isNavCategorySub, isPageLink } from './Navigation';
import PageLink from './PageLink';

function CategoryTop(props: NavCategoryTop) {
  const { value } = props;

  return (
    <>
      <div className='nav__list--maincategory'>
        {value.icon ? (
          <Image
            src={addBaseUrl(value.icon.file.src)}
            alt={value.icon.file.alt}
            className=''
            width={68}
            height={68}
            style={{
              objectFit: 'contain',
            }}
          />
        ) : null}
        {value.icon_svg ? (
          <Image src={addBaseUrl(value.icon_svg.file)} width={68} height={68} alt='' />
        ) : null}
        {value.title}
      </div>

      <ul className='subnav'>
        {value.sub_nav.map(item => {
          if (isNavCategorySub(item)) {
            return (
              <li className='nav__list-item' key={item.id}>
                <CategorySub {...item} />
              </li>
            );
          }
          if (isPageLink(item)) {
            return (
              <li className='nav__list-item' key={item.id}>
                <PageLink {...item} isCategoryTop />
              </li>
            );
          }
          if (isExternalLink(item)) {
            return (
              <li className='nav__list-item' key={item.id}>
                <ExternalLink {...item} />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </>
  );
}

export default CategoryTop;
