/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-redundant-roles */
import type {
  CustomNavitem,
  NavCategorySub,
  NavCategoryTop,
  NavExternalLink,
  NavPage,
} from '@/types';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Hamburger from '@components/Hamburger';
import { useRouter } from 'next/router';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import clsx from 'clsx';
import ExternalLink from './ExternalLink';
import PageLink from './PageLink';
import CategoryTop from './NavCategoryTop';

type Props = {
  data: CustomNavitem[];
  setNavOpen: Dispatch<SetStateAction<string>>;
  navOpen: string;
};

export function isPageLink(item: any): item is NavPage {
  return item?.type === 'page';
}

export function isExternalLink(item: any): item is NavExternalLink {
  return item?.type === 'link';
}

export function isNavCategoryTop(item: any): item is NavCategoryTop {
  return item?.type === 'nav_category_top';
}

export function isNavCategorySub(item: any): item is NavCategorySub {
  return item?.type === 'nav_category_sub';
}

function Navigation(props: Props) {
  const { data, navOpen, setNavOpen } = props;

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setNavOpen('closed');
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeError', handleRouteChange);
    };
  }, [router, setNavOpen]);

  const nav = data?.find(item => item.name === 'top');
  if (!nav) return null;

  return (
    <>
      <nav
        className={clsx('nav', {
          'nav--open': navOpen === 'navigation',
        })}
        id='main-nav'
        aria-label='Hauptnavigation'
      >
        <form action='/suche' className='searchform'>
          <input
            className='searchform__input'
            id='search'
            name='search'
            type='text'
            placeholder='Ich suche...'
          />
          <button type='submit' className='searchform__button'>
            <MagnifyingGlassIcon color='#0c2f6e' width={38} height={38} />
          </button>
        </form>
        <div className='wrapper'>
          <ul className='nav__list topnav'>
            {nav.menu?.map(item => {
              if (isNavCategoryTop(item)) {
                return (
                  <li className='nav__list-item' key={item.id}>
                    <CategoryTop {...item} />
                  </li>
                );
              }
              if (isPageLink(item)) {
                return (
                  <li className='nav__list-item' key={item.id}>
                    <PageLink {...item} />
                  </li>
                );
              }
              if (isExternalLink(item)) {
                return (
                  <li className='nav__list-item' key={item.id}>
                    <ExternalLink {...item} />
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      </nav>
      <Hamburger
        onClick={() => {
          if (navOpen === 'closed') setNavOpen('navigation');
          if (navOpen === 'navigation') setNavOpen('closed');
          if (navOpen === 'contactnavigation') setNavOpen('navigation');
        }}
        aria-label='Navigation umschalten'
        aria-controls='main-nav'
      />
    </>
  );
}

export default Navigation;
