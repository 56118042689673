import { NavExternalLink } from '@/types';
import React from 'react';
import { addBaseUrl } from '@utils/url';
import Image from 'next/image';

function ExternalLink(props: NavExternalLink) {
  const { value } = props;
  return (
    <a
      href={value.link}
      target='_blank'
      rel='noreferrer'
      className='nav__link nav__link--external button button--light'
    >
      {value.icon ? (
        <Image
          src={addBaseUrl(value.icon.file.src)}
          alt={value.icon.file.alt}
          className=''
          width={50}
          height={50}
          style={{
            objectFit: 'contain',
          }}
        />
      ) : null}
      {value.icon_svg ? (
        <Image src={addBaseUrl(value.icon_svg.file)} width={50} height={50} alt='' />
      ) : null}

      {value.override_title}
    </a>
  );
}

export default ExternalLink;
